var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":_vm.$t('menu.connection')}},[_c('a',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-switch',{attrs:{"checked-children":_vm.$t('connected.online'),"un-checked-children":_vm.$t('connected.all'),"default-checked":""},on:{"click":_vm.changeStatus}})],1),(_vm.loading)?_c('a-col',{attrs:{"span":16,"offset":11}},[_c('a-spin',{attrs:{"size":"large"}})],1):_c('div',[_c('a-descriptions',{attrs:{"size":"small","column":2}},[_c('a-descriptions-item',{attrs:{"label":_vm.$t('connected.online')}},[_c('span',{staticClass:"font-size-20 font-weight-bold"},[_vm._v(_vm._s(_vm.count))])]),_c('a-descriptions-item',{attrs:{"label":_vm.secondaryTitle}},[_c('span',{staticClass:"font-size-20 font-weight-bold"},[_vm._v(_vm._s(_vm.totalCount))])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-input-search',{attrs:{"placeholder":_vm.$t('personnel.chercher'),"enter-button":"","allowClear":""},on:{"change":_vm.onSearch,"search":_vm.onSearch}},[_c('a-icon',{attrs:{"type":"x"}})],1)],1)])],1),_c('a-list',{staticClass:"demo-loadmore-list",attrs:{"item-layout":"horizontal","data-source":_vm.users_on_connection_list,"pagination":{
      position: 'bottom',
      hideOnSinglePage: true,
      simple: true,
      defaultPageSize: _vm.pageSize,
    }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{class:_vm.selectedUser == item._id ? 'bg-secondary bg-gradient' : ''},[_c('a-list-item-meta',{on:{"click":function($event){return _vm.select_user(item._id)}}},[_c('a',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" ")]),_c('span',{attrs:{"slot":"avatar"},slot:"avatar"},[_c('a-badge',{attrs:{"dot":"","color":item.online ? 'green' : 'red'}},[_c('a-avatar',{attrs:{"src":item.photo
                  ? _vm.settings.base_url + '/' + item.photo.replaceAll('\\', '/')
                  : '-'}})],1)],1)])],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }