var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":19}},[_c('a-card',{staticStyle:{"width":"100%"},attrs:{"tab-list":_vm.tabList,"active-tab-key":_vm.activeKey},on:{"tabChange":_vm.callback}},[_c('a',{attrs:{"slot":"tabBarExtraContent","href":"#"},slot:"tabBarExtraContent"},[_c('a-range-picker',{attrs:{"ranges":{
              Today: [_vm.moment().startOf('day'), _vm.moment().endOf('day')],
              'This Week': [_vm.moment().startOf('week'), _vm.moment().endOf('week')],
              'This Month': [
                _vm.moment().startOf('month'),
                _vm.moment().endOf('month') ],
              'Last Three Months': [
                _vm.moment().subtract(3, 'months').startOf('month'),
                _vm.moment().endOf('month') ],
            },"show-time":"","defaultValue":_vm.initDates,"format":_vm.dateFormat},on:{"change":_vm.onChange}})],1),(_vm.MainStatsDataReady && _vm.noFilterOutgoing)?_c('main-stats',{key:'M' + _vm.loadKey,staticClass:"row mw-100",attrs:{"series":_vm.MainStatsData,"options":_vm.MainStatsoptions}}):_c('div',{staticClass:"card-body row mw-100"},[_c('a-spin')],1),(
            (_vm.activeKey == '1' || _vm.activeKey == '2') && _vm.secondaryStatsDataReady
          )?_c('secondary-stats',{key:'S' + _vm.loadKey,attrs:{"series":_vm.SecondaryStatsData,"options":_vm.SecondaryStatsOption}}):(_vm.activeKey == '1' || _vm.activeKey == '2')?_c('div',{staticClass:"card-body row mw-100"},[_c('a-spin')],1):_vm._e()],1)],1),_c('a-col',{attrs:{"span":5}},[(_vm.activeKey == '1')?_c('connected-users',{key:'CU1' + _vm.loadKey,attrs:{"loading":_vm.loadingInfo.parents,"users-list":_vm.parents_list,"secondary-title":_vm.$t('liste.total'),"title":_vm.$t('sms.parents'),"messageEnabled":true},on:{"updateSlected":_vm.updateSelected,"deleteSlected":_vm.deleteSelected}}):(_vm.activeKey == '2')?_c('connected-users',{key:'CU2' + _vm.loadKey,attrs:{"loading":_vm.loadingInfo.teachers,"users-list":_vm.teachers_list,"secondary-title":_vm.$t('liste.total'),"title":_vm.$t('actualite.teachers'),"messageEnabled":false},on:{"updateSlected":_vm.updateSelected,"deleteSlected":_vm.deleteSelected}}):(_vm.activeKey == '3')?_c('connected-users',{key:'CU3' + _vm.loadKey,attrs:{"loading":_vm.loadingInfo.admins,"users-list":_vm.admins_list,"secondary-title":_vm.$t('liste.total'),"title":_vm.$t('connected.school_user'),"messageEnabled":false},on:{"updateSlected":_vm.updateSelected,"deleteSlected":_vm.deleteSelected}}):_vm._e()],1),_c('a-col',{attrs:{"span":24}},[(_vm.MainStatsDataReady)?_c('detailed-stats',{attrs:{"data":_vm.filteredConnectedData}}):_c('div',{staticClass:"card-body"},[_c('a-spin')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }